import Page from "../uiComponents/Layouts/Page";
import {useNavigate, useParams} from "react-router-dom";
import { useMemo} from "react";

//@ts-ignore
const termsOfService = require("../assets/docs/Terms And Conditions.pdf");
//@ts-ignore
const privacyPolicy = require("../assets/docs/Privacy Policy Statement.pdf");


export default function LegalDocsPage(){
    const params = useParams();
    const navigate = useNavigate();

    const doc = useMemo(()=>{
        let doc = params.document;
        if(!doc) return navigate("/");
        if(doc.includes("terms")) return termsOfService;
        return privacyPolicy;
    }, [params, navigate])


    return <Page>
        <div className={"w-[90%] m-auto"}>
            <iframe title={"Legal documents"} src={`${doc}#toolbar=4`}
                className={"w-full h-screen bg-white"}
                    security={"Document"}
            />
        </div>
    </Page>
}

