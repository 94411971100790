import Page from "../uiComponents/Layouts/Page";
import {socials} from "../data/Contacts";
import IconBtn from "../uiComponents/Buttons/IconBtn";
import TextInput from "../uiComponents/Inputs/TextInput";
import TextArea from "../uiComponents/Inputs/TextArea";
import Button from "../uiComponents/Buttons/Button";
import React from "react";
import useContactUs from "../hooks/useContactUs";


export default function ContactPage(){
    const { message, sendMessage, change, spin } = useContactUs();

    return <Page className={"flex"}>
        <div className={"w-[90%] m-auto flex gap-10 flex-wrap-reverse md:flex-wrap"}>
            <div className={"w-max z-[10] bg-white drop-shadow-xl p-3 h-max"} >
                <h2>Social media</h2>
                {socials.map((social, i)=><span key={i} onClick={()=>window.open(social.link, "_blank")} className={"w-full min-h-[30px] rounded-lg p-1 hover:bg-blue-100 cursor-pointer m-auto grid grid-flow-col auto-cols-max gap-2 leading-[30px] "}>
                    <IconBtn Icon={social.icon} /> <p className={"w-max m-auto"}>{social.cont}</p>
                </span>)}
            </div>
            <div className={"flex-1 w-full "}>
                <div className={"w-1/2 h-1/2 z-[0] absolute bg-gradient-to-tr from-blue-200 to-red-200 blur-3xl top-0 "} />

                <form onSubmit={sendMessage} className={"w-full drop-shadow-xl bg-white grid gap-2 p-2"}>
                    <h2>Send us a message</h2>
                    <span className={"grid grid-flow-row md:grid-flow-col gap-2"}>
                        <TextInput name={"name"} value={message.name} onChange={change} label={"Your name"} />
                        <TextInput name={"email"} value={message.email} onChange={change} label={"Your email"} />
                    </span>
                    <TextInput name={"subject"} value={message.subject} onChange={change} label={"Subject"} />
                    <TextArea name={"message"} value={message.message} onChange={change} label={"Message"} />
                    <Button spin={spin} disabled={spin} text={"Submit"} className={"w-1/2 m-auto"} />
                </form>
            </div>
        </div>
    </Page>
}

