import React, {useEffect} from "react";


interface props extends React.HTMLAttributes<HTMLDivElement>{
    children: React.ReactNode;

}

export default function Page({children, ...rest}:props){

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <div data-aos={"zoom-out"} className={`w-full top-[5px] min-h-[calc(100vh-70px)] bg-white ${rest.className} `}>
        {children}
    </div>
}

