import {useNavigate} from "react-router-dom";
import {socials, usefulLinks} from "../../data/Contacts";
import IconBtn from "../Buttons/IconBtn";
import {IoLocation} from "react-icons/io5";
import {FiExternalLink} from "react-icons/fi";
import useContactUs from "../../hooks/useContactUs";
import TextInput from "../Inputs/TextInput";
import TextArea from "../Inputs/TextArea";
import Button from "../Buttons/Button";
import React from "react";


export default function Footer() {
    const nav = useNavigate();
    const {message, change, sendMessage, spin} = useContactUs();

    // hide in landing page
    return <footer className={"w-full min-h-[50vh] bg-gray-200 mt-[5rem] pt-5 "}>
        <div className={"w-full justify-around flex flex-wrap"}>
            <div className={"w-full flex-1 grid h-max gap-2"}>
                <h2>Follow us</h2>
                {socials.map((social, i) => <span key={i} onClick={() => window.open(social.link, "_blank")}
                                                  className={"w-1/2 min-h-[30px] rounded-lg p-1 hover:bg-blue-100 cursor-pointer m-auto grid grid-flow-col auto-cols-max gap-2 leading-[30px] "}>
                    <IconBtn Icon={social.icon}/> <p className={"w-max m-auto"}>{social.cont}</p>
                </span>)}
            </div>

            <div className={"h-max flex-1"}>
                <h2>Useful links</h2>
                {usefulLinks.map((link, i) => (
                    <button key={i} onClick={() => nav(link.link)}
                            className={"w-1/2 min-h-[30px] rounded-lg p-1 hover:bg-blue-100 cursor-pointer m-auto grid grid-flow-col auto-cols-max gap-2 leading-[30px] "}>
                        <FiExternalLink className={"m-auto text-blue-500"}/>
                        <p>{link.name}</p>
                    </button>
                ))}
            </div>

            <div className={"grid flex-1 gap-2 h-max"}>
                <div>
                    <h2>Location</h2>
                    <span className={"w-1/2 min-h-[30px] rounded-lg p-1 hover:bg-blue-100 cursor-pointer m-auto grid grid-flow-col auto-cols-max gap-2 leading-[30px] "}>
                        <IoLocation className={"fill-blue-400 m-auto"}/>
                        <p>CBD, Nairobi Kenya</p>
                    </span>
                </div>
                <form onSubmit={sendMessage} className={"w-[90%] m-auto bg-white grid gap-2 p-2"}>
                    <h2>Send us a message</h2>
                    <span className={"grid grid-flow-row md:grid-flow-col gap-2"}>
                        <TextInput name={"name"} value={message.name} onChange={change} label={"Your name"}/>
                        <TextInput name={"email"} value={message.email} onChange={change} label={"Your email"}/>
                    </span>
                    <TextInput name={"subject"} value={message.subject} onChange={change} label={"Subject"}/>
                    <TextArea name={"message"} value={message.message} onChange={change} label={"Message"}/>
                    <Button spin={spin} disabled={spin} text={"Submit"} className={"w-1/2 m-auto"}/>
                </form>
            </div>

        </div>
        <span className={"font-bold"}>Powered by Gonep &copy;{new Date().getFullYear()}</span>
    </footer>
}

