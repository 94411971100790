import {create} from "zustand";

interface useNotifObj {
    notification: { title: "Notification" | "Error!" | "Warning!", message: string},
    hide: ()=>void;
    showError: (err: any) => void;
    notify: (message: string) => void;
}

const useNotification = create<useNotifObj>((set, get)=>({
    notification: { title: "Notification", message: ""},

    hide: ()=>{
        setTimeout(()=>{
            set(p => ({...p, notification: {...p.notification, message: ""}}))
        }, 3500)
    },
    notify: (message)=>{
        set(prev=>({...prev, notification: {title: "Notification", message: message} }))
        get().hide()
    },
    showError: (err )=>{
        try {
            if(err.response){
                set(p => ({
                    ...p, notification: { title: "Warning!", message: err.response.data.message }}))
            }
        }catch (e) {
            set(p => ({
                ...p, notification: {title: "Error!", message: err.message}
            }))
        }
        get().hide();
    }

}))

export default useNotification;


