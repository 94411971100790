import {navPath} from "../react-app-env";

export const mainNavPaths: navPath[] = [
    {name: "Home", path: "" },
    {name: "About", path: "about" },
    {name: "Investors", path: "investors" },
    {name: "Join team", path: "join-team" },
    {name: "Contact", path: "contact" },
    {name: "Products", path: "products" },
    {name: "Careers", path: "careers" },
    {name: "Blog", path: "https://blog.gonepharm.com"},
]

