import useNotification from "./useNotification";
import React, {useState} from "react";
import sendReq from "../utils/axios";


export default function useContactUs(){
    const { notify, showError } = useNotification()
    const [spin, setSpin] = useState(false)

    const [message, setMessage] = useState({
        name: "", email: "", subject: "", message: ""
    })
    const [joinUsForm, setJoinUsForm] = useState({
        name: "", email: "", phone: "", role: "Investor"
    })

    const change = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        setMessage(prev=>({...prev, [e.target.name]:e.target.value}))
    }
    const joinUsChange = (e:React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) =>
        setJoinUsForm(p => ({...p, [e.target.name]:e.target.value}));

    const sendMessage = async (e: React.FormEvent<HTMLFormElement>)=> {
        e.preventDefault();
        setSpin(true)
        if(!message.name || !message.email || !message.subject) return showError("Name, email and subject are required!")
        await sendReq.post("/contacts", message)
            .then(({data})=>{
                notify(data?.message)
                setTimeout(()=>{
                    setMessage(prev=>({...prev, name: "", subject: "", email: "", message: ""}))
                }, 1500)
            })
            .catch(err=>{
                showError(err)
            })
            .finally(()=>setSpin(false))
    }
    const joinUs = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSpin(true)
        await sendReq.post("/join-us", joinUsForm)
            .then(({data})=>notify(data.message))
            .catch(err => {
                showError(err)
            })
            .finally(()=>setSpin(false))
    }
    return { message, change, sendMessage, spin, joinUs, joinUsChange }
}

