import useNotification from "../hooks/useNotification";
import {useMemo} from "react";


export default function NotificationBox(){
    const { notification:{title, message} } = useNotification();

    const color = useMemo(()=>{
        if(title === 'Error!') return "bg-red-500 text-white";
        if(title === "Warning!") return "bg-orange-300";
        return "bg-white"
    }, [title])

    if(!message) return <></>
    return <div  data-aos={"zoom-out"} className={`min-w-[300px] max-w-md min-h-[20vh] rounded-lg drop-shadow-2xl p-5 fixed top-[70px] left-0 right-0 m-auto z-[300] ${color} `}>
        <span className={"text-2xl font-bold"}>{title}</span>
        <p>{message}</p>
    </div>

}

