import {navPath} from "../../react-app-env";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

interface props {
    paths: navPath[]
}

export default function MobileDropDown({paths}:props){
    const [drop, setDrop] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const btnRef = useRef<HTMLButtonElement>(null)
    const navigate = useNavigate();

    useEffect(() => {
        try {
            const handleClickOutside = (event: MouseEvent) => {
                if (((dropdownRef.current && !dropdownRef.current.contains(event.target as Node)))
                    && (btnRef.current && !btnRef.current.contains(event.target as Node))) setDrop(false);
            };
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }catch (e) {

        }
    }, []);
    return <>
        <button ref={btnRef} onClick={()=>setDrop(!drop)} className={"w-[40px] h-[35px] grid rounded-lg "}>
            <div className={`w-full h-[5px] bg-blue-500 ${drop?"rotate-45":""}`} />
            <div className={`w-full h-[5px] bg-blue-500 ${drop?"rotate-45":""}`} />
            <div className={`w-full h-[5px] bg-blue-500 ${drop?"-rotate-45":""}`} />
        </button>
        {drop && <div ref={dropdownRef} data-aos={"zoom-out"}
            className={'fixed w-[70vw] top-[72px] left-0 right-0 ml-auto mr-auto h-max p-2 grid gap-2 bg-blue-200 bg-opacity-5 rounded-lg backdrop-blur-2xl shadow-2xl'}>
            {paths.map((path, i) => <button
                className={"w-full h-[3em] border font-bold text-[18px] border-solid rounded-lg leading-[3em] border-blue-500 "}
                key={i}
                onTouchEnd={()=>{
                    path.path.includes("https")?
                        window.open(path.path, "_blank"):
                        navigate(path.path)
                    setDrop(false)
                }}
            >
                {path.name}
            </button>)}
        </div>}
    </>
}
