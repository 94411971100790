import axios from "axios";

let path = window.location.href.includes("localhost") ?
    "http://localhost:3000/gonep" // uses this when running on localhost
    :
    "https://gonep-backend.onrender.com/gonep"

const sendReq = axios.create({
    baseURL: path
})

export default sendReq;
