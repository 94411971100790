import React from "react";


interface props extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    title?: string;
}
export default function PageSection({children, title, ...rest}:props){

    return <div className={"w-[90%] m-auto grid gap-5 "}>
        {title && <h1>{title}</h1>}
        <div {...rest} className={` ${rest.className}`} >
            {children}
        </div>
    </div>
}

