import Page from "../uiComponents/Layouts/Page";
import img from "../assets/images/kit_pexels-karolina-grabowska-4386466.webp"
import content from '../data/webcontent.json';

export default function ProductsPage(){

    return <Page className={"grid gap-5"}>
        <div className={"flex w-full h-[calc(100vh-50px)] z-[10]"}>
            <img className={"w-full h-full top-0 z-[0] opacity-80 absolute object-cover rounded-2xl"} src={img}
                 alt={""}/>
            <h1 className={"text-6xl text-white m-auto "}>CLINIC AT HAND CH05-F</h1>
        </div>
        <div className={"w-[90%] grid gap-4 m-auto"}>
            <h1>About the product</h1>
            <p>{content.our_product}</p>
        </div>
    </Page>
}

