import './App.css';
import AOS from "aos";
import 'aos/dist/aos.css'
import React, {useEffect} from 'react';
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import Four04Page from "./pages/Four04Page";
import InvestorJoinUsPage from "./pages/InvestorJoinUsPage";
import CareersPage from "./pages/CareersPage";
import ContactPage from "./pages/ContactPage";
import {Route, Routes} from "react-router-dom";
import ProductsPage from "./pages/ProductsPage";
import LegalDocsPage from "./pages/LegalDocsPage";
import Footer from "./uiComponents/Navigation/Footer";
import NavBar from "./uiComponents/Navigation/NavBar";
import NotificationBox from "./components/NotificationBox";
import aosConfig from "./utils/configs/aos.config";
import TeamJoinUsPage from "./pages/TeamJoinUsPage";

function App() {

    useEffect(() => {
        AOS.init(aosConfig)
    }, []);

    return (
        <div className="App w-full ">
            <NavBar />
            <NotificationBox /> {/** appears only during notification */}
            <Routes>
                {/*<Route index={true} element={<LandingPage />} />*/}
                <Route index={true} element={<HomePage />} />
                <Route path={"/about"} element={<AboutPage />} />
                <Route path={"/investors"} element={<InvestorJoinUsPage />} />
                <Route path={"/join-team"} element={<TeamJoinUsPage/>} />
                <Route path={"/contact"} element={<ContactPage />} />
                <Route path={"/products"} element={<ProductsPage />} />
                <Route path={"/careers"} element={<CareersPage />} />
                <Route path={"/legal/:document"} element={<LegalDocsPage />} />
                <Route path={"*"} element={<Four04Page />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
