import logo from '../../assets/images/logo1.png'
import {useNavigate} from "react-router-dom";
import {mainNavPaths} from "../../utils/Paths";
import NavBtn from "../Buttons/NavBtn";
import {socials} from "../../data/Contacts";
import IconBtn from "../Buttons/IconBtn";
import MobileDropDown from "../DropDown/MobileDropDown";
import useNavBarColor from "../../hooks/useNavBarColor";


const ClickableLogo = () => {
    const navigation = useNavigate();

    const goto = () => navigation("/")
    return <img className={"w-[40px] h-[40px] object-contain mt-auto mb-auto cursor-pointer "}
                onClick={goto} src={logo} alt={"logo"} />
}

export default function NavBar(){
    const { bgColor } = useNavBarColor();

    return <nav className={`w-full h-[3em] pl-2 pr-2 ${bgColor} sticky top-0 leading-[3em] flex justify-between ${bgColor.includes("transparent")?"shadow-none":"shadow-lg"} z-[800]`}>
        <ClickableLogo />
        <div className={"w-max h-max gap-2 mt-auto mb-auto hidden md:grid grid-flow-col"}>
            {mainNavPaths.map((path, i) => <NavBtn key={i} path={path} />)}
        </div>
        <div className={"w-max h-max grid grid-flow-col mt-auto mb-auto gap-2"}>
            {socials.map((social, i) => <IconBtn key={i} Icon={social.icon}
                onClick={()=>window.open(social.link, '_blank')}
            />)}
        </div>
        <div className={"w-max h-max p-2 grid md:hidden grid-flow-col mt-auto mb-auto gap-2"}>
            <MobileDropDown paths={mainNavPaths} />
        </div>
    </nav>
}

