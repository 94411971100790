import Page from "../uiComponents/Layouts/Page";
import content from "../data/webcontent.json";
import TextInput from "../uiComponents/Inputs/TextInput";
import Button from "../uiComponents/Buttons/Button";
import React from "react";
import useContactUs from "../hooks/useContactUs";

export default function InvestorJoinUsPage(){
    const {spin, joinUs, joinUsChange} = useContactUs();

    return <Page className={"flex"}>
        <div className={"w-[90%] grid gap-5 m-auto"}>
            <span>
                <div className={"absolute w-[30vw] h-[30vw] bg-gradient-to-tr from-blue-100 to-green-200 blur-3xl"} />
                <h1 className={"text-6xl"}>Join the revolution</h1>
            </span>
            <p className={"max-w-lg text-left m-auto"}>{content.investor_join_us}</p>

            <form onSubmit={joinUs} className={"w-full max-w-lg  m-auto p-3 bg-white rounded-lg drop-shadow-xl grid grid-flow-row gap-3"}>
                <h1 className={"text-2xl"}>Fill form</h1>
                <TextInput required={true} onChange={joinUsChange} name={"name"} label={"Your Name"} />
                <TextInput required={true} onChange={joinUsChange} name={"phone"} label={"Phone number"} type={'tel'} />
                <TextInput required={true} onChange={joinUsChange} name={"email"} label={"Your Email"} type={"email"} />
                <Button spin={spin} disabled={spin} className={"!w-1/2 m-auto"} text={"Join us"} />
            </form>
        </div>
    </Page>
}

