import {FaXTwitter} from "react-icons/fa6";
import {FaFacebook, FaInstagram, FaLinkedin} from "react-icons/fa";
import {ContactPath} from "../react-app-env";


export const socials: ContactPath[] = [
    {cont: "Twitter", link: "https://x.com/GonepLtd?s=20", icon: FaXTwitter},
    {cont: "Facebook", link: "https://www.facebook.com/Gonepharmaceuticals", icon: FaFacebook},
    {cont: "Instagram", link: "https://www.instagram.com/g.one_pharmaceauticals", icon: FaInstagram},
    {cont: "LinkedIn", link: "https://www.linkedin.com/company/g-one-pharmaceuticals", icon: FaLinkedin},
]

export const usefulLinks = [
    {name: "Terms of service", link: "/legal/terms"},
    {name: "Privacy policy", link: "/legal/privacy"},
]
