import Page from "../uiComponents/Layouts/Page";
import content from "../data/webcontent.json";
import Goodluck from "../assets/images/goodluck.webp"
import logo from "../assets/images/logo2.png"
import location from "../assets/images/Nairobi-pexels-antony-trivet-14168543.1.webp"

interface props {
    img: string;
    text: string;
    caption: string;
    title: string;
    imgRight?: boolean;
}
function AboutSection({img, text, caption, title, imgRight}:props){

    return <div className={`w-[90%] m-auto flex flex-wrap ${imgRight && "flex-row-reverse"} gap-2`}>
        <div className={"w-[300px] h-[350px] md:w-[20vmax] md:h-[25vmax]"}>
            <div className={"absolute w-1/2 h-1/2 right-0 bg-gradient-to-tr from-blue-200 to-green-400 rounded-full blur-3xl "} />
            <img src={img} className={"w-full h-[300px] md:h-[20vmax] rounded-full bg-white object-contain"} alt={caption} />
            <i>{caption}</i>
        </div>
        <div className={"flex-1 min-w-[300px] m-auto"}>
            <h1>{title}</h1>
            <p>{text}</p>
        </div>
    </div>
}


export default function AboutPage(){

    return <Page className={"grid gap-10"}>
        <AboutSection img={logo} title={"Who are we?"} text={content.who_we_are} caption={""} />
        <AboutSection img={Goodluck} imgRight={true} title={"A brief history"} caption={"Jeremiah Goodluck, CEO"} text={content.brief_history} />
        <AboutSection img={location} text={content.location} caption={"Nairobi CBD"} title={"Find us"} />
    </Page>
}

