import React, {useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {navPath} from "../../react-app-env";


interface props extends React.HTMLAttributes<HTMLButtonElement>{
    path: navPath;
}

export default function NavBtn({path, ...rest}:props){
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const bg = useMemo(()=>pathname === `/${path.path}` ?
        "bg-blue-400 text-white":"bg-white text-black", [pathname, path.path])
    const goto = () => path.path.includes("https") ? window.open(path.path) : navigate(`/${path.path}`)

    return <button {...rest} onClick={goto} className={`w-max !h-[2.5em] ${bg} leading-[2.5em] rounded-lg pl-2 pr-2 hover:bg-blue-100 ${rest.className}`} >
        {path.name}
    </button>
}

