import React from "react";


interface props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

export default function TextInput({label,...rest}:props){

    return <div className={"w-full h-max grid text-left "}>
        <label className={"text-[18px] text-blue-300 p-[0.5rem] "}>{label}</label>
        <input {...rest} className={`w-full h-[2.5em] border-solid border-2 border-transparent focus:border-green-400 focus:bg-blue-50 bg-gray-100 p-2 rounded-lg outline-transparent ${rest.className}`} />
    </div>
}

