import React from "react";

interface props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
}
export default function TextArea({label, ...rest}:props){

    return <div className={"grid w-full h-max text-left gap-2"}>
        <label className={"text-[18px] text-blue-300 p-[0.5rem] "}>{label}</label>
        <textarea {...rest} className={`w-full p-2 rounded-lg outline-transparent border-solid border-2 border-transparent focus:border-green-400 focus:bg-blue-50 bg-gray-100 ${rest.className}`} />
    </div>
}

