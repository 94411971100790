import {useEffect, useMemo, useState} from "react";

export default function useNavBarColor(){
    const start = 0;
    const [scrolled, setScrolled] = useState(false)
    const bgColor: "bg-white"|"bg-transparent" = useMemo(()=>scrolled ? "bg-white" : "bg-transparent", [scrolled])

    const handleScroll = ()=>{
        if((window.scrollY - start) > 50) setScrolled(true)
        else setScrolled(false)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return ()=>{
            window.removeEventListener('scroll', handleScroll)
        }
    }, []);

    return { bgColor }
}

