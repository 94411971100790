import Page from "../uiComponents/Layouts/Page";
import banner from "../assets/images/Landing Page.jpg";
import PageSection from "../uiComponents/Layouts/PageSection";
import content from '../data/webcontent.json'
import Button from "../uiComponents/Buttons/Button";
import shakeHands from '../assets/images/shakeHands.webp'
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export default function HomePage(){
    const nav = useNavigate();
    const [readMore, setReadMore] = useState(false)


    return <Page className={"w-full !top-[-3em] grid gap-10"}>
        <div className={"w-full h-[50ch] flex "} >
            <img src={banner} alt={"banner"} className={"w-full h-full m-auto object-cover md:object-contain brightness-75 bg-[#0f0100]"} />
            <div className={"absolute w-full h-max !text-white top-0 mt-auto mb-auto bottom-0 left-0 right-0 z-50 "}>
                <h1 className={"text-white text-[4vmax] leading-normal font-black"}>GONEP PHARMACEUTICALS</h1>
                <p className={"text-2xl font-bold"}>Transforming Healthcare Through Advancement</p>
            </div>
        </div>
        <PageSection title={"GONEP"} className={"grid gap-4"} >
            <p className={"w-full line-clamp-4"}>{content.who_we_are}</p>
            <Button onClick={()=>nav("/about")} text={"More about us..."} className={"m-auto"} />
        </PageSection>
        <PageSection title={"Our Values"} className={" h-max grid md:grid-cols-2 z-[2] flex-wrap gap-4 "}>
            <div className={"w-1/4 h-full m-auto !z-[0] bg-opacity-30 top-0 bottom-0 absolute blur-3xl bg-gradient-to-tr from-blue-100 to-red-200"} />
            <div data-aos={"fade-up"} className={"flex-1 bg-white min-w-[300px] h-full rounded-lg drop-shadow-xl p-10 hover:shadow-2xl"}>
                <h2>Our Vision</h2>
                <p>{content.vision}</p>
            </div>
            <div data-aos={"fade-up"} data-aos-delay={"300"} className={"flex-1 bg-white min-w-[300px] h-full rounded-lg drop-shadow-xl p-10 hover:shadow-2xl"}>
                <h2>Our Mission</h2>
                <p>{content.mission}</p>
            </div>
        </PageSection>
        <PageSection data-aos={'fade-up'} title={"What is Clinic At Hand?"} className={"grid gap-5"} >
            <div className={"w-full"}>
                <p className={`${readMore ? "line-clamp-none" : "line-clamp-6"} md:line-clamp-none`}>{content.our_product}</p>
                <Button onClick={()=>setReadMore(!readMore)} text={`Read ${readMore?"less":"more..."}`} className={"m-auto md:hidden "} />
            </div>
            <div data-aos={"zoom-in"} data-aos-duration={"750"} className={"w-full h-[70vh] flex"}>
                <img src={shakeHands}
                     className={"absolute w-full h-full rounded-3xl object-cover "}
                     alt={""}/>
                <span className={"m-auto grid gap-4 text-white "}>
                    {/* set to blue */}
                    <div className={"absolute w-[40vw] h-[30vh] bg-black "}/>
                    <h1 className={"text-[5ch] h-max gap-1 text-white"}>Join us today as we journey towards a healthier tomorrow</h1>
                    <p className={"text-xl"}>Are you an investor looking for the next big opportunity? Join us today for an exciting journey of a prosperous
                    future.</p>
                    <Button onClick={() => nav("/join_us")} text={"Join us today"} className={"m-auto shadow-xl"}/>
                </span>
            </div>
        </PageSection>
    </Page>
}

