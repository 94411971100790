import Page from "../uiComponents/Layouts/Page";
import content from "../data/webcontent.json";
import img from '../assets/images/groupPhoto.jpg'
import ShowCareer from "../components/ShowCareer";

export default function CareersPage(){
    // fetch career opportunities
    // const [careers, setCareers] = useState([])
    const careers:any  = []

    return <Page className={"grid gap-5"}>
        <div className={"flex w-full h-[calc(100vh-50px)] z-[10]"}>
            <img className={"w-full h-full filter brightness-50 absolute object-top object-cover"} src={img}
                 alt={""}/>
            <span className={"m-auto text-white"}>
                    <h1 className={"text-white"}>Our core values</h1>
                    <ul className={"list-inside w-max m-auto"}>
                        {content.core_values.map((val, i) => <li key={i}>{val}</li>)}
                    </ul>
                </span>
        </div>
        <div className={"w-[90%] m-auto min-h-[60vh] "}>
            <div className={"w-[30vw] h-[30vw] absolute top-50 z-[-1] bg-gradient-to-tr from-green-100 to-blue-200 blur-3xl "}/>
            <h1>Careers</h1>
            <div className={"w-full min-h-[20vh] grid grid-cols-2 p-2"}>
                {careers.length < 1 &&
                    <h3 className={"text-3xl translate-y-1/2 left-0 right-0 top-0 bottom-0 m-auto absolute font-bold"}>
                        No positions available at this time
                    </h3>}
                {careers.map((_career: any, i:number) => <ShowCareer key={i}/>)}
            </div>
        </div>
    </Page>
}

