

export default function ShowCareer(){

    return <div className={"bg-white p-3 drop-shadow-xl rounded-lg"}>
        <h3 className={"text-2xl font-bold"}>Job title</h3>
        <p>Description</p>
        <p></p>
    </div>
}


